import React, {useEffect, useState} from 'react';
import './App.css';
import {getCount} from "./utils/requests";

function App() {
  const [cnt, setCnt] = useState<number>()

  useEffect(() => {
    getCount().then(resp => setCnt(resp.count))
  }, [])

  return (
    <div className="App">
      {cnt !== undefined ? `count ${cnt}` : ''}
    </div>
  );
}

export default App;
