import {CountResponse} from "../models";

export async function getCount(): Promise<CountResponse> {
    return await get('/count')
}

async function get<R>(uri: string, requestParams?: { [key: string]: string | undefined }): Promise<R> {
    if (requestParams) {
        let queryStr = '?'
        for (let name in requestParams) {
            const value = requestParams[name]
            if (value) {
                if (queryStr.length > 1)
                    queryStr += '&'
                queryStr += `${name}=${value}`
            }
        }
        if (queryStr.length > 1)
            uri += queryStr
    }
    const resp = await fetch(uri, {
        headers: {
            method: 'GET',
            Accept: 'application/json'
        }
    })
    return await resp.json() as R
}